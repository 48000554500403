.Map {
  height: 100%;
  width: 100%;
}

.detailImg {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.logoFooter {
  max-height: 500px;
  max-width: 100%;
  padding: 20px;
  margin: 0 auto 2rem;
}

.logo {
  max-height: 60px;
}

.logoSide {
  max-width: 60%;
  margin: 0px auto 0.5rem;
}

.sideMenu {
  background-color: #fff;
}

.HeroImg {
  width: 80%;
}
